<template>
  <div>
    <CContainer>
      <CRow>
        <CCol md="6">
          <CCardGroup>
            <CCard>
              <CCardHeader>
                <strong>Создание заказа</strong>
              </CCardHeader>
              <CCardBody>
                <CForm @submit.prevent="createOrder">
                  <CSelect v-model="formType" label="Форма площадки" :options="[
                    { label: 'Прямоугольник', value: 'quad' },
                    { label: 'Круг', value: 'round' },
                  ]"></CSelect>

                  <CInput v-model="code" label="Код заказа"> </CInput>

                  <CInput v-model="maxLength" label="Максимальная длина площадки, м">
                  </CInput>
                  <CInput v-model="maxWidth" label="Максимальная ширина площадки, м">
                  </CInput>
                  <!-- autocomplete v-if="user && user.group !== 'Dealer' && !manualClientInput"
                    :search="searchCounterparty"
                    placeholder="Найти контрагента"
                    aria-label="Найти контрагента"
                    :get-result-value="getCounterpartyLookupEntry"
                    @submit="fillCounterparty"
                  ></autocomplete -->
                  <CInput v-model="client" label="Имя клиента"> </CInput>
                  <CInput v-model="clientPhone" label="Телефон клиента">
                  </CInput>
                  <CInput v-model="clientEmail" label="Почта клиента"> </CInput>
                  <br />
                  <CRow>
                    <CCol>
                      <CButton color="success" type="submit"> Создать </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { router } from "../../router/index";
import Autocomplete from "@trevoreyre/autocomplete-vue";
import "@trevoreyre/autocomplete-vue/dist/style.css";
import { crmContactsService } from "../../_services";
import { dateFormat } from "../../utils";
import { russianStringToFloat } from "../../utils.js";

export default {
  data() {
    return {
      user: null,
      code: "",
      maxLength: 12,
      maxWidth: 12,
      manualClientInput: false,
      firstName: null,
      client: null,
      clientPhone: null,
      clientEmail: null,
      formType: 0,
      orderStatuses: {
        new: "Новый",
        archive: "Архивный",
        payed: "Оплачен",
        delivered: "Отгружен",
      },
    };
  },
  components: {
    Autocomplete,
  },
  created() {
    this.storeSubscription = this.$store.subscribe((mutation) => {
      if (mutation.type === "users/getCurrentSuccess") {
        this.user = mutation.payload;
        this.code = `${dateFormat(new Date())}-${this.user.id}-${this.user.order_count + 1
          }`;
      } else if (mutation.type === "orderPlatforms/createSuccess") {
        router.push(`/orders/${mutation.payload.request.orderId}/plan`);
      }
      if (mutation.type === "orders/createSuccess") {
        this.createPlatform(
          mutation.payload.request.max_length,
          mutation.payload.request.max_width,
          mutation.payload.response.id,
          mutation.payload.request.form_type
        );
      }
    });
    this["users/getCurrent"]();
  },
  beforeDestroy() {
    if (this.storeSubscription) {
      this.storeSubscription();
    }
  },

  methods: {
    ...mapActions([
      "orders/create",
      "orderPlatforms/create",
      "users/getCurrent",
    ]),

    searchCounterparty(input) {
      return crmContactsService.query(input);
    },

    getCounterpartyLookupEntry(entry) {
      return entry.name;
    },

    fillCounterparty(entry) {
      this.client = entry.name;
    },

    createOrder() {
      let maxLength = russianStringToFloat(this.maxLength);
      let maxWidth = russianStringToFloat(this.maxWidth);

      const orderData = {
        code: this.code,
        max_length: maxLength,
        max_width: maxWidth,
        status: "new",
        first_name: this.user.first_name,
        client: this.client,
        client_phone: this.clientPhone,
        client_email: this.clientEmail,
        owner_id: 0,
        form_type: this.formType,
      };
      this["orders/create"](orderData);
    },

    createPlatform(length, width, orderId, formType) {
      const area =
        Math.round((parseFloat(length * width) + Number.EPSILON) * 100) / 100;

      const halfLength = length / 2;
      const halfWidth = width / 2;
      let dots;


      if (formType != "round") {
        dots = this.getNewPlatformQuadDots(halfWidth, halfLength);
      } else {
        dots = this.getNewPlatformRoundDots(halfWidth, halfLength);
      }
      const platformToAdd = {
        orderId,
        orderPlatform: {
          area,
          dots: dots,
        },
      };
      this["orderPlatforms/create"](platformToAdd);
    },

    getNewPlatformQuadDots(halfWidth, halfLength) {
      return [
        {
          x: -halfLength,
          y: -halfWidth,
        },
        {
          x: halfLength,
          y: -halfWidth,
        },
        {
          x: halfLength,
          y: halfWidth,
        },
        {
          x: -halfLength,
          y: halfWidth,
        },
      ];
    },

    getNewPlatformRoundDots(halfWidth, halfLength) {
      let dots = [];
      for (let i = 0; i < 360; i += 10) {
        let angle = (i / 180) * Math.PI;
        dots.push({
          x: Math.cos(angle) * halfLength,
          y: Math.sin(angle) * halfWidth,
        });
      }
      return dots;
    },

    changeToManualClientInput() {
      this.manualClientInput = true;
    },
  },
};
</script>
